import React, { useState } from 'react';
import { Layout, Menu, Col, Row, Collapse } from 'antd';
import { RightOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Panel } = Collapse;

const MenuPage = ({height}) => {
  const [selectedMainMenu, setSelectedMainMenu] = useState(null);
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);

  // Define the menu structure
  const menuData = {
    'Our Company': {
      News: '/news',
      'Who we are': null,
      'What we do': null,
      Leadership: null,
      'Culture and Diversity': null,
    }
    ,
    'Our Products': {
      Kardiogen: '/products/kardiogen'
    },
    'Our Science': {
      'Webinar': [
        {
          'title': 'Webinar 1',
          'link': '/ourscience/1'
        },
        {
          'title': 'Webinar 2',
          'link': '/ourscience/hoi-thao-nguy-co-da-gen-co-nguon-goc-da-chung-toc'
        }
      ],
      'Article': {

      },
    },
    'Our Talents': {'Careers': null},
  };

  // Determine if a menu item has sub-items
  const hasSubItems = (menu) => {
    if(Array.isArray(menuData[menu])){
      return true;
    }
    if (menuData[menu] && typeof menuData[menu] === 'object') {
      return true;
    }
    return false;
  }

  // Handle main menu click
  const handleMainMenuClick = (key) => {
    setSelectedMainMenu(key);
    setSelectedSubMenu(null);
  };

  // Handle submenu click
  const handleSubMenuClick = (key) => {
    setSelectedSubMenu(key);
    {menuData[selectedMainMenu][key].map((item) => (
      console.log(item.link)
    ))}
  };

  return (
    <Layout id="menu-page" style={{height:height, backgroundColor:'#fff', padding:'0'}}>
      <Content style={{ padding: '20px' }}>
        <div className="menu-container">
          {/* Desktop View */}
          <Row gutter={16} className="desktop-menu">
            {/* Column 1: Main Menu */}
            <Col span={8}>
              <Menu
                mode="inline"
                selectedKeys={[selectedMainMenu]}
                onClick={({ key }) => handleMainMenuClick(key)}
                style={{ borderRight: 'none' }}
              >
                {Object.keys(menuData).map((menu) => (
                  <Menu.Item
                    key={menu}
                    style={{ display: 'flex', justifyContent: 'space-between', borderBottom:'1px solid #ddd', borderRadius:'0px' }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom:'1px solid #ddd', borderRadius:'0px' }}>
                      <span>{menu}</span>
                      {hasSubItems(menu) && <RightOutlined />} {/* Show the icon for items with sub-items */}
                    </div>
                    
                  </Menu.Item>
                ))}
              </Menu>
            </Col>

            {/* Column 2: Sub Menu */}
            <Col span={8}>
              {selectedMainMenu && (
                <Menu
                  mode="inline"
                  selectedKeys={[selectedSubMenu]}
                  onClick={({ key }) => handleSubMenuClick(key)}
                  style={{ borderRight: 'none' }}
                >
                  {Object.keys(menuData[selectedMainMenu]).map((subCategory) => (
                    <Menu.Item
                      key={subCategory}
                      style={{ display: 'flex', justifyContent: 'space-between', borderBottom:'1px solid #ddd', borderRadius:'0px' }}
                    >
                      <div style={{borderBottom:'1px solid #ddd', borderRadius:'0px' }}>
                        {
                            menuData[selectedMainMenu][subCategory] && !Array.isArray(menuData[selectedMainMenu][subCategory]) ?
                            <a href={menuData[selectedMainMenu][subCategory]} style={{ display: 'flex', justifyContent: 'space-between', borderBottom:'1px solid #ddd', borderRadius:'0px' }}>
                              <span>{subCategory}</span>
                              {hasSubItems(selectedMainMenu) && <RightOutlined />}
                            </a> : <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom:'1px solid #ddd', borderRadius:'0px' }}>
                              <span>{subCategory}</span>
                              {hasSubItems(selectedMainMenu) && <RightOutlined />}
                            </div>
                        }
                        
                      </div>
                    </Menu.Item>
                  ))}
                </Menu>
              )}
            </Col>

            {/* Column 3: Third Level Items */}
            <Col span={8}>
              {selectedMainMenu &&
                selectedSubMenu &&
                !Array.isArray(menuData[selectedMainMenu]) &&
                menuData[selectedMainMenu][selectedSubMenu] && (
                  <Menu mode="inline">
                    {menuData[selectedMainMenu][selectedSubMenu].map((item) => (
                    <Menu.Item key={item.title} style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <a href={String(item.link)}>{item.title}</a>
                    </Menu.Item>
                  ))}
                  </Menu>
                )}
            </Col>
          </Row>

          {/* Mobile View */}
          <Collapse className="mobile-menu" accordion>
            {/* Main Menu */}
            <Panel header="Main Menu" key="main-menu">
              <Menu
                mode="inline"
                selectedKeys={[selectedMainMenu]}
                onClick={({ key }) => handleMainMenuClick(key)}
              >
                {Object.keys(menuData).map((menu) => (
                  <Menu.Item
                    key={menu}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span>{menu}</span>
                    {hasSubItems(menu) && <RightOutlined />} {/* Show the icon for items with sub-items */}
                  </Menu.Item>
                ))}
              </Menu>
            </Panel>

            {/* Sub Menu */}
            {selectedMainMenu && (
              <Panel header="Sub Menu" key="sub-menu">
                <Menu
                  mode="inline"
                  selectedKeys={[selectedSubMenu]}
                  onClick={({ key }) => handleSubMenuClick(key)}
                >
                  {Object.keys(menuData[selectedMainMenu]).map((subCategory) => (
                    <Menu.Item
                      key={subCategory}
                      style={{ display: 'flex', justifyContent: 'space-between', borderBottom:'1px solid #ddd', borderRadius:'0px' }}
                    >
                      <div style={{borderBottom:'1px solid #ddd', borderRadius:'0px' }}>
                        {
                            menuData[selectedMainMenu][subCategory] && !Array.isArray(menuData[selectedMainMenu][subCategory]) ?
                            <a href={menuData[selectedMainMenu][subCategory]} style={{ display: 'flex', justifyContent: 'space-between', borderBottom:'1px solid #ddd', borderRadius:'0px' }}>
                              <span>{subCategory}</span>
                              {hasSubItems(selectedMainMenu) && <RightOutlined />}
                            </a> : <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom:'1px solid #ddd', borderRadius:'0px' }}>
                              <span>{subCategory}</span>
                              {hasSubItems(selectedMainMenu) && <RightOutlined />}
                            </div>
                        }
                        
                      </div>
                    </Menu.Item>
                  ))}
                </Menu>
              </Panel>
            )}

            {/* Third Level Items */}
            {selectedMainMenu && selectedSubMenu && !Array.isArray(menuData[selectedMainMenu]) && menuData[selectedMainMenu][selectedSubMenu] && (
              <Panel header="Items" key="items">
                <Menu mode="inline">
                  {menuData[selectedMainMenu][selectedSubMenu].map((item) => (
                    // item is object with title and link, i want show title and link to the page
                    <Menu.Item key={item.title} style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <a href={item.link}>{item.title}</a>
                    </Menu.Item>
                  ))}
                </Menu>
              </Panel>
            )}
          </Collapse>
        </div>
      </Content>

      {/* Responsive Styles */}
      <style jsx>{`
        .desktop-menu {
          display: flex;
        }

        .mobile-menu {
          display: none;
        }

        @media (max-width: 768px) {
          .desktop-menu {
            display: none;
          }

          .mobile-menu {
            display: block;
          }
        }
      `}</style>
    </Layout>
  );
};

export default MenuPage;

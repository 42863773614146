import React, {useState, useEffect} from "react";
import { Row, Col, Typography, Image, Card, Grid, Breadcrumb } from "antd";

import { useParams } from "react-router-dom";
import NavigationLandingPage from "../Layout/NavigationLandingPage";
import FooterLandingPage from "../Layout/FooterLandingPage";
import imgKar from "./assets/Kardiogen.jpg"

const { Title, Paragraph } = Typography;

const { useBreakpoint } = Grid;

const products = [{
  id: 'kardiogen',
  name: "KARDIOGEN",
  description: `
Polygenic Risk Score of Coronary Artery Disease
<b>sampleType: 3 – 4ml Whole Blood Sample/EDTA tube</b>
<b>turnAroundTime: 28 days</b>
<b>laboratory: MedGenome Lab (India)</b>
<b>capAccredition: YES</b>

Đa hình đơn nucleotide (SNP) được xác định bằng SNP arrays, xử lý qua các bước khuếch đại, phân mảnh, lai DNA với bead-chips, nhuộm huỳnh quang và quét. Dữ liệu đạt chuẩn dùng tính Điểm nguy cơ KARDIOGEN (KRS) dự đoán nguy cơ CAD, dựa trên mô hình thống kê tối ưu hóa cho người Nam Á. Hồ sơ di truyền thực hiện tại phòng thí nghiệm chuẩn CAP, đảm bảo dữ liệu DNA phù hợp tiêu chuẩn quốc tế.`,
  imageUrl: imgKar,
}];

const ProductDetail = () => {
  const { productId } = useParams(); // Get the news ID from the route
  const [bannerHeight, setBannerHeight] = useState('auto');
  const [headerHeight, setHeaderHeight] = useState(0);
  const screens = useBreakpoint(); // Get the current screen size
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      // Calculate available height for banner
      const headerHeight = document.querySelector('.header').offsetHeight;
      const footerHeight = document.querySelector('.footer-component').offsetHeight;
      setHeaderHeight(headerHeight);
      setBannerHeight(`calc(100vh - ${headerHeight+footerHeight}px)`);
    };

    // Set initial height
    handleResize();
    
    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Find the specific news item based on the ID
    const product = products.find((item) => item.id === productId);
    console.log('product', product);
    setProduct(product);
  }, [productId]);

  return (
    <>
      <NavigationLandingPage />
      <div style={{paddingTop:`${headerHeight}px`, minHeight:bannerHeight, padding: `${headerHeight}px ${screens.md?200:30}px`}}>
        <Breadcrumb style={{margin:'10px 0'}}>
          <Breadcrumb.Item>
            <a href="/">Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item> {product && product.name}</Breadcrumb.Item>
        </Breadcrumb>
        {
          product && (
            <Card style={{ margin: "auto", padding: 20 }}>
              <Row gutter={[16, 16]}>
                {/* Image Section */}
                <Col xs={24} sm={12}>
                  <Image
                    src={product.imageUrl}
                    alt={product.name}
                    width="100%"
                    style={{ borderRadius: 8 }}
                  />
                </Col>
                {/* Details Section */}
                <Col xs={24} sm={12}>
                  <Title level={3}>{product.name}</Title>
                  <Paragraph style={{ whiteSpace:'pre-wrap' }}>
                    <span dangerouslySetInnerHTML={{ __html: product.description }} />
                  </Paragraph>
                  <a href='/kardiogen.pdf' download>Product manual</a>
                </Col>
              </Row>
            </Card>
          )
        }
      </div>
      <FooterLandingPage />
    </>

  );
};

export default ProductDetail;
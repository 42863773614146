import React, { useState,useCallback, useEffect } from 'react';
import { Table, Card, Button, Select, Input, Tag, Space, Modal, Typography, message } from 'antd';
import './DashboardPage.css';
import { PlusOutlined, ExportOutlined, EditOutlined, NotificationOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { backendUrl } from '../../constants';
import { fetchWithToken } from '../share/apiUtils';
import UploadFileComponent from '../share/UploadFileComponent';

const { Search } = Input;

const formatNumber = (number) => {
  const formatter = new Intl.NumberFormat('de-DE'); // German locale
  return formatter.format(number);
};
const statusMapping = {
  'NEW': 'Mới',
  'WAITING_FOR_PAYMENT': 'Mẫu đạt - Chờ thanh toán',
  'FAILED': 'Mẫu không đạt',
  'PROCESSING': 'Đã thanh toán - Đang xử lý',
  'REFUND': 'Hoàn trả',
  'DELAY': 'Delay',
  'COMPLETED': 'Đã hoàn thành',
  'CANCELLED': 'Huỷ',
};

const notifyStatus = [
  "NEW",
  'FAILED',
  "WAITING_FOR_PAYMENT",
  "PROCESSING",
  "COMPLETED",
  "DELAY",
];

const TableContent = () => {
  const navigate = useNavigate();
  const [sourceData, setSourceData] = useState([]);
  const [status, setStatus] = useState('');
  const [partner, setPartnerCode] = useState('');
  const [textSearch, setTextSearch] = useState('');
  const [selectOrderId, setSelectOrderId] = useState('');
  const [newStatus, setNewStatus] = useState('');
  const [isOpenStatusChangeModal, setIsOpenStatusChangeModal] = useState(false);
  const [fileIds, setFileIds] = useState([]);
  const [isOpenNotifyModal, setIsOpenNotifyModal] = useState(false);
  const [isNotifyModelLoading, setIsNotifyModelLoading] = useState(false);
  const [isNotiDoctor, setIsNotiDoctor] = useState('');
  const [isNotiPatient, setIsNotiPatient] = useState('');
  const [curNotifyStatus, setCurNotifyStatus] = useState([]);
  const [failReason, setFailReason] = useState('');
  const [curStatus, setCurStatus] = useState('')
  const [resultExpectDate, setResultExpectDate] = useState('');
  const today = new Date();
  const futureDate = new Date(today.setDate(today.getDate() + 28));
  const defaultDate = futureDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD


  const fetchTests = useCallback( async (status, partner, text) => {
    try {
      const baseUrl = `${backendUrl}/get_orders`;
      const url = new URL(baseUrl);
      const params = new URLSearchParams();
      if (status) params.append('status', status);
      if (partner) params.append('partner', partner);
      if (text) params.append('text', text);
      url.search = params.toString();
      const response = await fetchWithToken(url.toString(), {
        method: 'GET',
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
      }
  
      const data = await response.json();
      setSourceData(data);
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  }, []);

  const fetchIsNotified = useCallback( async (order_id) => {
    try {
      const baseUrl = `${backendUrl}/check_notified/`;
      const url = new URL(baseUrl);
      const params = new URLSearchParams();
      url.search = params.toString();
      setIsNotifyModelLoading(true);

      const response = await fetchWithToken(url.toString(), {
        method: 'POST',
        body: JSON.stringify({
          order_id: order_id,
        })
      });
      const data = await response.json();
      setIsNotifyModelLoading(false);
      if (!response.ok) {
        message.error('Failed to check notified', 2);
      }
      setIsNotiDoctor(data.is_notified_doctor);
      setIsNotiPatient(data.is_notified_patient);
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  }, []);

  const changeStatus = async (orderId, newStatus) => {
    try {
      const baseUrl = `${backendUrl}/change_order_status/`;
      const url = new URL(baseUrl);
      const expect_date = resultExpectDate || defaultDate;
      const response = await fetchWithToken(url.toString(), {
        method: 'POST',
        body: JSON.stringify({
          order_id: orderId,
          status: newStatus,
          result_file_ids: fileIds,
          failed_reason: newStatus === 'FAILED' ? failReason : undefined,
          result_expect_date: newStatus === 'PROCESSING' ? expect_date : undefined,
        })
      });
      if (!response.ok) {
        message.error('Failed to change status', 2);
        
      }else{
        message.success('Change status success!', 2);
      }
      setFileIds([]);
      setNewStatus('');
      setIsOpenStatusChangeModal(false);
      setSelectOrderId('');
      fetchTests(status, partner, textSearch);
      return;
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  }

  const send_notification = async (orderId, role, resultExpectDate, failReason) => {
    try {
      const baseUrl = `${backendUrl}/send_notification/`;
      const url = new URL(baseUrl);
      const response = await fetchWithToken(url.toString(), {
        method: 'POST',
        body: JSON.stringify({
          order_id: orderId,
          role: role,
          result_expect_date: resultExpectDate,
          failed_reason: failReason
        })
      });
      if (!response.ok) {
        message.error('Failed to send notification', 2);
      }else{
        message.success('Send notification success!', 2);
      }
      setCurNotifyStatus('');
      setIsOpenNotifyModal(false);
      setIsNotiDoctor('');
      setIsNotiPatient('');
      setIsNotifyModelLoading(false);
      return;
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  }

  useEffect(() => {
    fetchTests();
  }, [fetchTests]);

  useEffect(() => {
    fetchTests(status, partner, textSearch);
  }, [textSearch, status, partner, fetchTests]);

  useEffect(() => {
    if (isOpenNotifyModal) {
      fetchIsNotified(selectOrderId);
    }
  }, [fetchIsNotified, isOpenNotifyModal, selectOrderId]);

  const columns = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1, // Incrementing index starting from 1
      key: 'number',
    },
    {
      title: 'Mã Đơn hàng',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      width: 260,
      render: (status, record) => {
        let diffDays = undefined
        if (status === 'PROCESSING' || status === 'DELAY'){
          const date = new Date(record.result_expect_date);
          const currentDate = new Date();
          const diffTime = date - currentDate;
          diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          if (diffDays <= 3) {
            status = 'DELAY'
          }
        }
        let curType = 'default';
        if (status === 'COMPLETED' || status === 'REFUND') {
          curType = 'success';
        } else if (status === 'DELAY' || status === 'FAILED') {
          curType = 'error';
        } else if (status === 'PROCESSING' || status === 'WAITING_FOR_PAYMENT') {
          curType = 'warning';
        }
  
        return <>
          <Tag color={curType}> {statusMapping[status]?.toUpperCase()}</Tag>
          <EditOutlined style={{ color: 'green' }} onClick={() => {
            setSelectOrderId(record.id);
            setCurStatus(status)
            setNewStatus(status);
            setIsOpenStatusChangeModal(true);
          }} />
          {status === 'FAILED' && <div style={{paddingTop:'5px'}}>Lý do: {record.failed_reason}</div>}
          {(status === 'PROCESSING' || status === 'DELAY') && <div style={{paddingTop:'5px'}}>Ngày dự kiến: {record.result_expect_date}</div>}
          {(diffDays!== undefined && (status === 'PROCESSING' || status === 'DELAY')) && <div style={{paddingTop:'5px'}}>Ngày còn lại: {diffDays} ngày</div>}
        </>;
      }
    },
    {
      title: 'Đối tác',
      dataIndex: 'partner_code',
      key: 'partner_code',
      render: (partner_code) => {
        if (partner_code === 'Taman') {
          return 'Tâm An';
        } else {
          return 'Client';
      }},
    },
    {
      title: 'Bác sĩ chỉ định',
      render: (text, record) => record.doctor_id.full_name,
      key: 'name_doctor',
      width: 200,
    },
    {
      title: 'Tên bệnh nhân',
      render: (text, record) => record.patient_id.full_name,
      key: 'name_patient',
      width: 200,
    },
    {
      title: 'Thành tiền (VNĐ)',
      render: (text, record) => formatNumber(record.total_amount) + ' VND',
      key: 'final_price',
    },
    {
      title: 'Điểm lấy mẫu',
      dataIndex: 'sampling_facility',
      key: 'sampling_address',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        let statusAction = record.status;
        let diffDays = undefined
        if (record.status === 'PROCESSING' || record.status === 'DELAY'){
          const date = new Date(record.result_expect_date);
          const currentDate = new Date();
          const diffTime = date - currentDate;
          diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          if (diffDays <= 3) {
            statusAction = 'DELAY'
          }
        }

        return (
          <Space size="middle">
            <EditOutlined style={{ color: 'green' }} onClick={() => {
              navigate('/edit-test?id=' + record.id);
            }} />
            {notifyStatus.includes(statusAction) && (
              <NotificationOutlined 
                style={{ color: 'green' }} 
                onClick={() => {
                  setCurNotifyStatus(statusAction);
                  setSelectOrderId(record.id);
                  setIsOpenNotifyModal(true);
                }}
              />
            )}
            
          </Space>
        )
      }
    },

  ];
  
  return (
    <>
      <Modal
        title="Thay đổi trạng thái"
        open={isOpenStatusChangeModal}
        onOk={()=>{
          changeStatus(selectOrderId, newStatus);
        }}
        onCancel={()=>{
          setFileIds([]);
          setNewStatus('');
          setIsOpenStatusChangeModal(false);
          setSelectOrderId('');
        }}
      >
        <Card bordered={false} style={{padding:'30px 0'}} >
          <Typography.Text style={{display:'block', margin:'10px 10px 10px 0'}}>
            Trạng thái hiện tại: <strong>{statusMapping[sourceData.filter(item => item.id === selectOrderId)[0]?.status]}</strong>
          </Typography.Text>
          <Typography.Text style={{marginRight: '10px'}}>
            Trạng thái mới:
          </Typography.Text>
          <Select
            style={{width: 150}}
            showSearch
            placeholder="Chọn trạng thái"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={[
              { value: 'NEW', label: 'Mới' },
              { value: 'WAITING_FOR_PAYMENT', label: 'Mẫu đạt - Chờ thanh toán' },
              { value: 'FAILED', label: 'Mẫu không đạt'},
              { value: 'PROCESSING', label: 'Đã thanh toán - Đang xử lý' },
              { value: 'REFUND', label: 'Hoàn trả' },
              { value: 'DELAY', label: 'Delay' },
              { value: 'COMPLETED', label: 'Đã hoàn thành' },
              { value: 'CANCELLED', label: 'Huỷ' },
            ]}
            onChange={(value) => {
              setNewStatus(value);
              }
            }
          />
          {(newStatus === 'COMPLETED' || (curStatus === 'COMPLETED' && curStatus === undefined)) && (
            <div style={{margin: '20px 10px 10px 0'}}>
              <Typography.Text>
                File kết quả:
              </Typography.Text>
              <UploadFileComponent 
                  onResetFiles={(fileList)=>{
                    if(fileList === undefined || fileList.length === 0) {
                      setFileIds([]);
                      return;
                    }
                    const ids = fileList.filter(item => item.status === 'done').map(file => file.response? file.response[0].id : file.uid);
                    setFileIds(ids);
                  }}
              />
            </div>
          )}
          {(newStatus === 'FAILED' || (curStatus==='FAILED' && newStatus === undefined)) && (
            <div style={{margin: '20px 10px 10px 0'}}>
            <Typography.Text>
              Lý do:
            </Typography.Text>
            <Input
              value={failReason}
              onChange={(data) =>{
                setFailReason(data.target.value);
              }}
            ></Input>
          </div>
          )}
          {(newStatus === 'PROCESSING' || (curStatus==='PROCESSING' && newStatus === undefined)) && (
            <div style={{margin: '20px 10px 10px 0'}}>
            <Typography.Text>
              Ngày dự kiến có kết quả:
            </Typography.Text>
            <Input
              type='date'
              defaultValue={defaultDate}
              onChange={(data) =>{
                setResultExpectDate(data.target.value);
              }}
            ></Input>
          </div>
          )}
        </Card>
      </Modal>
      <Modal
        title="Gửi thông báo thay đổi trạng thái"
        open={isOpenNotifyModal}
        onCancel={()=>{
          setIsOpenNotifyModal(false);
        }}
        footer={[
          <Button 
            key="submit" 
            type="primary"
            onClick={() => {
              send_notification(selectOrderId, 'patient', resultExpectDate, failReason); 
            }}
          >
            Thông báo bệnh nhân
          </Button>,
          <Button 
            key="submit" 
            type="primary"
            hidden={(curNotifyStatus !== 'COMPLETED' && curNotifyStatus !== 'PROCESSING' && curNotifyStatus !== 'DELAY')} 
            onClick={() => {
              send_notification(selectOrderId, 'doctor', resultExpectDate, failReason); 
            }}
          >
            Thông báo bác sĩ
          </Button>,
          <Button 
            key="cancel" 
            onClick={() => setIsOpenNotifyModal(false)}
          >
            Huỷ
          </Button>
        ]}
        
      >
        <Card bordered={false} style={{padding:'30px 0'}} >
          {isNotifyModelLoading ? (
            <Typography.Text>Loading...</Typography.Text>
          ) : (
            <> {curNotifyStatus === 'COMPLETED' && (
                <Typography.Text style={{display:'block', margin:'10px 10px 10px 0'}}>
                  Trạng thái thông báo cho bác sĩ: <strong>{isNotiDoctor ? 'Đã thông báo' : 'Chưa thông báo'}</strong>
                </Typography.Text>
              )}
              <Typography.Text style={{display:'block', margin:'10px 10px 10px 0'}}>
                Trạng thái thông báo cho bệnh nhân: <strong>{isNotiPatient ? 'Đã thông báo' : 'Chưa thông báo'}</strong>
              </Typography.Text>
            </>
          )}
        </Card>
        {(curNotifyStatus === 'DELAY') && (<>
            <div style={{margin: '20px 10px 10px 0'}}>
              <Typography.Text>
                Ngày dự kiến có kết quả:
              </Typography.Text>
              <Input
                type='date'
                defaultValue={defaultDate}
                onChange={(data) =>{
                  setResultExpectDate(data.target.value);
                }}
              ></Input>
              </div>
              <div>
              <Typography.Text></Typography.Text>
              <Typography.Text>
                Lý do delay:
              </Typography.Text>
              <Input
                onChange={(data) =>{
                  setFailReason(data.target.value);
                }}
              ></Input>
              </div>
          </>
        )}
      </Modal>
      <Card bordered={false} style={{padding:'30px'}} >
        <div style={{display:'flex', justifyContent:'space-between'}}>
          <div style={{marginBottom: 20}}>
            <Button type="primary" htmlType="submit" style={{ boxShadow:'none', marginRight: 10}} icon={<ExportOutlined />}> 
              <span>Xuất mẫu</span>
            </Button>
            <Button type="primary" htmlType="submit" style={{ boxShadow:'none'}} icon={<PlusOutlined />} 
              onClick={()=>navigate('/create-test')}
            > 
              <span>Tạo mẫu</span>
            </Button>
          </div>
          <div style={{marginBottom: 20}}>
            <Select
              style={{margin: '0 5px'}}
              showSearch
              placeholder="Chọn trạng thái"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                { value: 'NEW', label: 'Mới' },
                { value: 'WAITING_FOR_PAYMENT', label: 'Mẫu đạt - Chờ thanh toán' },
                { value: 'FAILED', label: 'Mẫu không đạt'},
                { value: 'PROCESSING', label: 'Đã thanh toán - Đang xử lý' },
                { value: 'REFUND', label: 'Hoàn trả' },
                { value: 'DELAY', label: 'Delay' },
                { value: 'COMPLETED', label: 'Đã hoàn thành' },
                { value: 'CANCELLED', label: 'Huỷ' },
              ]}
              onChange={(value) => {
                setStatus(value);
              }}
            />
            <Select
              showSearch
              style={{margin: '0 5px'}}
              placeholder="Đối tác "
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                { value: 'Taman', label: 'Tâm An' },
                { value: 'Client', label: 'Khách hàng cá nhân' },
              ]}
              onChange={(value) => {
                setPartnerCode(value);
              }}
            />
            <Search 
              style={{margin: '0 10px', width: 250}} 
              placeholder="input search text"
              onChange={(e) => setTextSearch(e.target.value)}
            />
          </div>
        </div>

        <Table dataSource={sourceData} columns={columns} />
      </Card>
    </>
  );
};

export default TableContent;